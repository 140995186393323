import { createSlice } from '@reduxjs/toolkit'

import { apiGet, handleApiErrorResponse } from '@placementsapp/jslibs/api'
import { addErrorNotification } from '@placementsapp/jslibs/store/notificationsSlice'

export const slice = createSlice({
  name: 'stripe',
  initialState: {
    clientSecret: '',
    paymentMethods: [],
    loading: true,
    updating: 0,
    paymentSuccessUrl: '',
    setupSuccessUrl: '',
    savePaymentInfo: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    incrementUpdating: (state) => {
      state.updating += 1
    },
    decrementUpdating: (state) => {
      state.updating -= 1
    },
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload.client_secret
    },
    setPaymentSuccessUrl: (state, action) => {
      state.paymentSuccessUrl = action.payload.success_url
    },
    setSetupSuccessUrl: (state, action) => {
      state.setupSuccessUrl = action.payload.success_url
    },
    setSavePaymentInfo: (state, action) => {
      state.savePaymentInfo = action.payload.save_payment_info === 'true'
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload
    },
  },
})

export const {
  setLoading,
  incrementUpdating,
  decrementUpdating,
  setQueryResults,
  setClientSecret,
  setPaymentSuccessUrl,
  setSetupSuccessUrl,
  setPaymentMethods,
  setSavePaymentInfo,
} = slice.actions

export const createPaymentIntent =
  ({ paymentId, attemptPayNow = false, savePaymentInfo = false }) =>
  (dispatch) => {
    dispatch(setLoading(true))
    apiGet(
      `/stripe/create_payment_intent?payment_id=${paymentId}&attempt_pay_now=${attemptPayNow}&setup_future_usage=${savePaymentInfo}`,
      {}
    )
      .then((responseJson) => {
        if (attemptPayNow) {
          if (responseJson['payment_status'] === 'succeeded') {
            window.location.href = responseJson['success_url']
          } else {
            handleApiErrorResponse(responseJson, (errorJson) =>
              dispatch(addErrorNotification(errorJson))
            )
          }
        } else {
          dispatch(setSavePaymentInfo(responseJson))
          dispatch(setClientSecret(responseJson))
        }
        dispatch(setPaymentSuccessUrl(responseJson))
      })
      .catch((response) => {
        handleApiErrorResponse(response, (errorJson) =>
          dispatch(addErrorNotification(errorJson))
        )
      })
      .finally(() => dispatch(setLoading(false)))
  }

export const createSavePaymentIntent =
  (paymentId, successUrl) => (dispatch) => {
    dispatch(setLoading(true))
    let url = `/stripe/create_save_payment_method_intent`
    if (paymentId) {
      url += `?payment_id=${paymentId}`
    } else if (successUrl) {
      url += `?success_url=${successUrl}`
    }
    apiGet(url, {})
      .then((responseJson) => {
        dispatch(setClientSecret(responseJson))
        dispatch(setSetupSuccessUrl(responseJson))
      })
      .catch((response) => {
        handleApiErrorResponse(response, (errorJson) =>
          dispatch(addErrorNotification(errorJson))
        )
      })
      .finally(() => dispatch(setLoading(false)))
  }

export const fetchSavedPaymentMethodsAsync = () => (dispatch) => {
  dispatch(setLoading(true))
  apiGet(`/stripe/get_saved_payment_methods`)
    .then((responseJson) => {
      dispatch(setPaymentMethods(responseJson.results))
    })
    .catch((response) => {
      handleApiErrorResponse(response, (errorJson) =>
        dispatch(addErrorNotification(errorJson))
      )
    })
    .finally(() => dispatch(setLoading(false)))
}

export const removeSavedPaymentMethodAsync =
  (payment_method_id, successCallback = () => {}, failureCallback = () => {}) =>
  (dispatch) => {
    dispatch(setLoading(true))
    apiGet(
      `/stripe/remove_saved_payment_method?payment_method_id=${payment_method_id}`,
      {}
    )
      .then((/*responseJson*/) => {
        successCallback()
      })
      .catch((response) => {
        handleApiErrorResponse(response, (errorJson) => {
          dispatch(addErrorNotification(errorJson))
          failureCallback()
        })
      })
      .finally(() => dispatch(setLoading(false)))
  }

export const selectPayment = (state) => state.stripe.payment
export const selectTotal = (state) => state.stripe.total
export const selectLoading = (state) => state.stripe.loading
export const selectUpdating = (state) => state.stripe.updating
export const selectClientSecret = (state) => state.stripe.clientSecret
export const selectPaymentSuccessUrl = (state) => state.stripe.paymentSuccessUrl
export const selectSetupSuccessUrl = (state) => state.stripe.setupSuccessUrl
export const selectSavedPaymentMethods = (state) => state.stripe.paymentMethods
export const selectSavePaymentInfo = (state) => state.stripe.savePaymentInfo

export default slice.reducer
