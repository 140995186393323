import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Layout, Col, Row, notification, List } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

import DefaultLogo from 'images/placementsio_logo_white.png'

import { selectSession } from '@placementsapp/jslibs/store/sessionSlice'
import {
  selectNotifications,
  removeNotification,
} from '@placementsapp/jslibs/store/notificationsSlice'
import UserMenu from '@placementsapp/uxlibs/Navbar/components/UserMenu'

const { Header } = Layout

const openNotification = (data, dispatch) => {
  const errors = (
    <>
      {Object.entries(data.errors || {}).map(([key, value]) => {
        return (
          <List key={key}>
            <List.Item>
              <List.Item.Meta
                avatar={<WarningOutlined />}
                title={`${key}:`}
                description={value}
              />
            </List.Item>
          </List>
        )
      })}
    </>
  )
  notification[data.type]({
    key: data.id,
    top: 50,
    duration: data.duration !== undefined ? data.duration : 0,
    message: <h3>{data.msg}</h3>,
    description: errors,
    onClose: () => dispatch(removeNotification(data)),
  })
}

export default function Navbar() {
  const dispatch = useDispatch()

  const session = useSelector(selectSession)
  const notifications = useSelector(selectNotifications)

  const { current_publisher_org = {}, current_user = {} } = session

  useEffect(() => {
    notifications.forEach((data) => openNotification(data, dispatch))
  }, [notifications])

  return (
    <Header className={'uxlibs-navbar'}>
      <Row justify="space-between">
        <Col>
          <Row justify={'start'} gutter={16} wrap={false}>
            <Col>
              <a href="/">
                <img
                  className="current_org_logo"
                  src={current_publisher_org.display_logo || DefaultLogo}
                  alt="logo"
                />
              </a>
            </Col>
            <Col>
              <a href="/">
                <h3 className="publisher-org-display-name">
                  {current_publisher_org.display_name || ''}
                </h3>
              </a>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify={'end'} gutter={16} wrap={false}>
            {/* TODO global search */}
            {/* <Col>{current_user.first_name && <GlobalSearch />}</Col> */}
            <Col>
              {current_user.first_name && (
                <UserMenu current_user={current_user} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}
