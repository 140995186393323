import _ from 'lodash'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import I18n from 'i18n-js'

export const ANT_FORM_URL_RULES = [
  {
    type: 'url',
    message: 'Please enter a valid URL starting with http(s)://',
  },
  {
    validator: (_, value) =>
      value.includes(' ')
        ? Promise.reject(new Error(`${I18n.t('no_whitespace')}`))
        : Promise.resolve(),
  },
]

/**
 * Get base 64 data url mime type as string or null
 * @param {string} dataUrl - Data url
 */
export function getBase64MimeType(dataUrl) {
  if (typeof dataUrl === 'string') {
    let mime = dataUrl.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
    if (mime && mime.length) {
      return mime[1]
    }
  }
  return null
}

/**
 * Get url slug. Example: for `/foo/bar` return `bar`
 */
export function getUrlSlug() {
  try {
    return location.pathname
      .split('/')
      .pop()
      .match(/^[A-z,_,-]+/g)
      .pop()
  } catch (error) {
    return ''
  }
}

/**
 * Get url base path. Example: for `/foo/bar` return `/foo`
 */
export function getUrlBasePath() {
  try {
    return location.pathname.match(/^\/[A-z,_,-]+/g)[0] || ''
  } catch (error) {
    return ''
  }
}

/**
 * Get url query key param value, or default or provided, else undefined
 * @param {string} paramKey - Param key
 * @param {any} defaultValue - Defalt value to return if falsy
 */
export function getUrlQueryParam(paramKey, defaultValue) {
  const params = new URLSearchParams(location.search)
  return params.get(paramKey) || defaultValue
}

/**
 * Get url query key param value as an int, or default or provided, else NaN
 * @param {string} paramKey - Param key
 * @param {any} defaultValue - Defalt value to return if falsy
 */
export function getQueryParamAsInt(paramKey, defaultValue) {
  return parseInt(getUrlQueryParam(paramKey, defaultValue))
}

/**
 * Delete url query param keys/values indicated or all if passed empty array as input.
 * Uses `history.pushState` if available
 * @param {Array} values - Array containing keys to delete from url query params
 */
export function clearUrlQueryParams(values = []) {
  if (values.length > 0) {
    const params = new URLSearchParams(location.search)
    for (const key of values) {
      params.delete(key)
    }
    if (history !== undefined && isFunction(history.pushState)) {
      const str = params.toString()
      history.pushState('', '', `${location.pathname}${str ? '?' : ''}${str}`)
    }
    return params
  } else {
    if (history !== undefined && isFunction(history.pushState)) {
      history.pushState('', '', `${location.pathname}`)
    }
    return new URLSearchParams('')
  }
}

/**
 * Sets (key: value) pairs from input in url query params. Clears existing params if `initial` is empty string.
 * Uses `history.pushState` if available
 * @param {Object} values - Object containing key: value pairs
 * @param {string} initial - String containing initial url query params such as from `location.search` or empty string
 */
export function setUrlQueryParams(values, initial = '') {
  const params = new URLSearchParams(initial)
  for (const [key, value] of Object.entries(values)) {
    params.set(key, value)
  }
  if (history !== undefined && isFunction(history.pushState)) {
    const str = params.toString()
    history.pushState('', '', `${location.pathname}${str ? '?' : ''}${str}`)
  }
  return params
}

/**
 * Sets new or updates existing (key: value) pairs in url query params.
 * Uses `history.pushState` if available
 * @param {Object} values - Object containing key: value pairs
 */
export function updateUrlQueryParams(values) {
  return setUrlQueryParams(values, location.search)
}

/**
 * Title case input string: replace underscore with space and upercase first letter of each word
 * @param {string} str - Input string to title case
 */
export function titleCase(str) {
  return (str || '')
    .toLowerCase()
    .replace('_', ' ')
    .replace(/\b(\w)/g, (s) => s.toUpperCase())
}

/**
 * Constrain range of values to within min and max, inclusive
 * @param {number} num - Input num to constrain
 * @param {number} min - Min value
 * @param {number} max - Max value
 */
export function clamp(num, min, max) {
  if (max < min) {
    return NaN
  }
  return Math.min(Math.max(num, min), max)
}

/**
 * Filter input array to items matching term
 * @param {Array} objects - List of objects to filter
 * @param {string} matchTerm - Term to filter on
 * @param {Array} paths - Paths in each object to match with matchTerm
 */
export function filterByPaths(objects, matchTerm, paths) {
  return objects.filter((obj) => {
    for (let path of paths) {
      const value = get(obj, path, '')
      if (
        typeof value === 'string' &&
        value.toLowerCase().includes(matchTerm.toLowerCase())
      ) {
        return true
      }
    }
  })
}

export function index_labels_by_value(options) {
  return _.reduce(
    options,
    (acc, { label, value }) => {
      acc[`${value}`] = label
      return acc
    },
    {}
  )
}
