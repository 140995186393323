import { createSlice } from '@reduxjs/toolkit'

export const INFO_MESSAGE_TYPE = 'info'
export const SUCCESS_MESSAGE_TYPE = 'success'
export const WARNING_MESSAGE_TYPE = 'warning'
export const ERROR_MESSAGE_TYPE = 'error'

const push = (state, action, type) => {
  if (action.payload.msg) {
    state.push({
      type: type,
      ...action.payload,
      id: state.length + 1,
    })
  }
}

export const slice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    addNotification: (state, action) => {
      push(state, action, INFO_MESSAGE_TYPE)
    },
    addSuccessNotification: (state, action) => {
      push(state, action, SUCCESS_MESSAGE_TYPE)
    },
    addWarningNotification: (state, action) => {
      push(state, action, WARNING_MESSAGE_TYPE)
    },
    addErrorNotification: (state, action) => {
      push(state, action, ERROR_MESSAGE_TYPE)
    },
    removeNotification: (state, action) => {
      return state.filter((n) => n.id !== action.payload.id)
    },
  },
})

export const {
  addNotification,
  addSuccessNotification,
  addWarningNotification,
  addErrorNotification,
  removeNotification,
} = slice.actions

export const selectNotifications = (state) => state.notifications

export default slice.reducer
