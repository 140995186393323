import merge from 'lodash/merge'
import isFunction from 'lodash/isFunction'

const abortControllers = {}

const getAbortSignal = (url, params, abortKey) => {
  const controllerKey = `
    ${params.method || 'GET'}_
    ${url.split('?')[0]}
    ${abortKey ? `_${abortKey}` : ''}
  `
  if (controllerKey in abortControllers) {
    abortControllers[controllerKey].abort()
    delete abortControllers[controllerKey]
  }
  abortControllers[controllerKey] = new AbortController()
  return abortControllers[controllerKey].signal
}

export const apiFetch = (url, params = {}, headers = null, abortKey = null) => {
  const _headers = merge(
    {
      'x-csrf-token': document.querySelector('[name=csrf-token]').content,
    },
    headers
  )
  const _params = merge(
    {
      headers: new Headers(_headers),
      cache: 'no-store',
      signal: getAbortSignal(url, params, abortKey),
    },
    params
  )
  if (process.env.NODE_ENV === 'development') {
    console.log('Dev - Handling api request', url, _params)
  }
  return fetch(url, _params)
    .then((response) => {
      if (!response.ok) throw response
      return response.json()
    })
    .catch((response) => {
      if (
        process.env.NODE_ENV === 'development' &&
        response.name !== 'AbortError'
      ) {
        console.error('Dev - ApiFetch error response:', response)
      }
      throw response
    })
}

export const apiGet = (url, params = {}, abortKey = null) => {
  return apiFetch(url, merge({}, params, { method: 'GET' }), {}, abortKey)
}

export const apiPut = (url, params = {}, abortKey = null) => {
  return apiFetch(
    url,
    merge({}, params, { method: 'PUT' }),
    { 'content-type': 'application/json' },
    abortKey
  )
}

export const apiPatchMultiPartData = (url, params = {}, abortKey = null) => {
  return apiFetch(url, merge({}, params, { method: 'PATCH' }), {}, abortKey)
}

export const apiPatch = (url, params = {}, abortKey = null) => {
  return apiFetch(
    url,
    merge({}, params, { method: 'PATCH' }),
    { 'content-type': 'application/json' },
    abortKey
  )
}

export const apiPostMultiPartData = (url, params = {}, abortKey = null) => {
  return apiFetch(url, merge({}, params, { method: 'POST' }), {}, abortKey)
}

export const apiPutMultiPartData = (url, params = {}, abortKey = null) => {
  return apiFetch(url, merge({}, params, { method: 'PUT' }), {}, abortKey)
}

export const apiPost = (url, params = {}, abortKey = null) => {
  return apiFetch(
    url,
    merge({}, params, { method: 'POST' }),
    { 'content-type': 'application/json' },
    abortKey
  )
}

export const apiDelete = (url, params = {}, abortKey = null) => {
  return apiFetch(
    url,
    merge({}, params, { method: 'DELETE' }),
    { 'content-type': 'application/json' },
    abortKey
  )
}

export const handleApiErrorResponse = (response, callback) => {
  if (response && response.name === 'AbortError') {
    if (process.env.NODE_ENV === 'development') {
      console.log('Dev - aborted request')
    }
  } else if (response && response.statusText && isFunction(response.json)) {
    response
      .json()
      .then((errorJson) => {
        errorJson.msg = errorJson.msg || response.statusText
        callback(errorJson)
      })
      .catch(() => {
        callback({ msg: response.statusText })
      })
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.error('Dev - HandleApiErrorResponse:', response)
    }
    callback({ msg: 'Invalid api response' })
  }
}
