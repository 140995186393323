import React from 'react'
import { Avatar, Dropdown, Menu, Row, Col } from 'antd'
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons'

export default ({ current_user = {} }) => {
  const userMenu = () => {
    return (
      <Menu>
        <Menu.Item key={'profile'} icon={<UserOutlined />}>
          <a href={'/profile'}>Profile</a>
        </Menu.Item>
        <Menu.Item key={'settings'} icon={<SettingOutlined />}>
          <a href={'/settings'}>Settings</a>
        </Menu.Item>
        <Menu.Item key={'logout'} icon={<LogoutOutlined />}>
          <a href={'/users/sign_out'}>Log out</a>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown
      className={'user-dropdown-menu'}
      overlay={userMenu}
      placement="bottomRight"
    >
      <Row gutter={16} style={{ cursor: 'pointer' }}>
        <Col>
          <Avatar icon={<UserOutlined />} src={current_user.avatar_url} />
        </Col>
        <Col>
          <div className="current-user-label">{`${current_user.first_name} ${current_user.last_name}`}</div>
        </Col>
      </Row>
    </Dropdown>
  )
}
