import { createSlice, createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'

export const slice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {},
})

// export const {} = slice.actions // Unexpected empty object pattern. (no-empty-pattern)

export const selectCurrentOrg = (state) => state.session?.current_org

export const selectSession = (state) => state.session
export const select_session = selectSession

export const selectCurrentUserId = (state) =>
  get(state, 'session.current_user.id', null)

export const selectPaymentOption = (state) =>
  get(state, 'session.current_org.payment_option', 'credit_card')

export const reports_feature_enabled_selector = createSelector(
  [select_session],
  (session) => !!session?.current_org?.options?.reports_feature_enabled
)

export default slice.reducer
